.timer {
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  .progress {
    border-radius: 3px;
    height: 6px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: rgba(250, 215, 68, 0.3);
    &-value {
      height: 4px;
      border-radius: 3px;
      box-sizing: border-box;
      margin: 1px;
      background-color: rgba(250, 215, 68, 1);
    }
  }
}
