.App {
  * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
  }
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  width: 100vw; //100vw on mobile
  height: 100vh;
  //mobile test styling
  justify-content: center;
  display: flex;
  flex-direction: column;
  //test,xse
  box-sizing: border-box;
  padding: 20px 20px 0 20px;

  .container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s all ease;

    button {
      -moz-user-select: none;
      user-select: none;
      -webkit-user-select: none;
      margin: 5px;
      padding: 5px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 16pt;
      background-color: transparent;
      border: none;
      transition: 0.3s all ease;
      outline: none;
      cursor: pointer;
      // &:hover {
      //   transform: scale(1.05);
      //   color: rgb(250,215,68);
      // }
      &:hover,
      &:active,
      &:focus {
        transform: scale(1.08);
      }
    }
  }

  .onStart,
  .onPause,
  .onOver,
  .onStats {
    & * {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24pt;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease;
    color: #000;

    span {
      margin: -10px 0 0 40px;
      letter-spacing: 40px;
    }
  }

  .onPlay {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16pt;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: 0.3s all ease;
    color: #000;

    .flag {
      font-size: 10em;
      text-align: center;
    }
    .choices {
      margin: 0 0 20px 0;
    }
    button {
      outline: none;
      padding: 5px 0;
      margin-bottom: 6px;
      border-radius: 3px;
      border: 1px solid #bababa;
      width: 75%;
    }
  }

  .ads {
    opacity: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 320px;
    height: 100px;
    box-shadow: -15px 0px 15px -20px rgba(0, 0, 0, 0.75),
      15px 0px 15px -20px rgba(0, 0, 0, 0.75);
    background-color: rgba(200, 200, 180, 0.3);
  }
}

.Loading {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  text-align: center;
  align-items: center;

  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16pt;
}

@media (min-width: 961px) {
  .App {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
  }
  body {
    background-color: #efefef;
  }
}

@media all and (display-mode: standalone) {
  .addToHomeScreen {
    display: none;
  }
}

.onStart > button:hover,
.onStart > button:active {
  animation: threeDAnim 0.6s infinite;
  color: white;
}

@keyframes threeDAnim {
  0% {
    text-shadow: 2px 2px 0px red, -2px -2px 0px deepskyblue;
  }
  13% {
    text-shadow: 0px 2px 0px red, 0px -2px 0px deepskyblue;
  }
  25% {
    text-shadow: -2px 2px 0px red, 2px -2px 0px deepskyblue;
  }
  38% {
    text-shadow: -2px 0px 0px red, 2px 0px 0px deepskyblue;
  }
  50% {
    text-shadow: -2px -2px 0px red, 2px 2px 0px deepskyblue;
  }
  68% {
    text-shadow: 0px -2px 0px red, 0px 2px 0px deepskyblue;
  }
  75% {
    text-shadow: 2px -2px 0px red, -2px 2px 0px deepskyblue;
  }
  88% {
    text-shadow: 2px 0px 0px red, -2px 0px 0px deepskyblue;
  }
  100% {
    text-shadow: 2px 2px 0px red, -2px -2px 0px deepskyblue;
  }
}
